import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import history from "./helpers/history";
import Index from "./containers/Index";
import Contacto from "./containers/Contacto";
import Terminos from "./containers/Terminos";
//import Despacho from "./containers/Despacho";
import Politicas from "./containers/Politicas";
import Preguntas from "./containers/Preguntas";
import Quees from "./containers/Quees";
import Comocomprar from "./containers/Comocomprar";
//Tracking
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

function App() {
  history.listen((location) => {
    ReactGA.pageview(location.pathname);
    ReactPixel.pageView();
    ReactPixel.fbq("track", "PageView");
    console.log(location.pathname);
  });
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/Contacto" component={Contacto} />
        <Route exact path="/Terminos" component={Terminos} />
        <Route exact path="/Comocomprar" component={Comocomprar} />
        <Route exact path="/Quees" component={Quees} />
        {/* <Route exact path="/Despacho" component={Despacho} /> */}
        <Route exact path="/Politicas" component={Politicas} />
        <Route exact path="/Preguntas" component={Preguntas} />
        <Route exact path="/Success" component={Index} />
      </Switch>
    </Router>
  );
}

export default App;
