import React from "react"
import Base from "../layout/Base"
import Products from "../components/Products";
import history from "../helpers/history"
import { ReactComponent as LogoWhite } from "../assets/svg/logoplantbblanco.svg"
import { ReactSVG } from "react-svg";
import userIcon from "../assets/svg/userIcon.svg"
import envelopeIcon from "../assets/svg/envelopeIcon.svg"
import phoneIcon from "../assets/svg/phoneIcon.svg"
import textIcon from "../assets/svg/textIcon.svg"

const Contacto = () => {
    const handleClose = () =>{
        history.push("/")
    }
    return (
        <Base>
              <div id='collection-component-1632323254850' ></div>
                <div class="modal is-active">
                <a href="/"><div class="modal-background" onClick=""></div></a>
                <div class="modal-content">
                    <div className="modal-green">
                        
                        <form action="https://usebasin.com/f/beed7f732f9f" method="post" className="form">
                            <LogoWhite style={{width: "100px", height: "40px", marginBottom: "30px"}}/>
                            <div className="inputContent">
                                <ReactSVG src={userIcon} className="is-pulled-left"/><input type="text" name="nombre" placeholder="Nombre" className="is-pulled-right"/>
                            </div>
                            <div className="inputContent">
                                <ReactSVG src={userIcon} className="is-pulled-left"/><input type="text" name="apellido" placeholder="Apellido" className="is-pulled-right" />
                            </div>
                            <div className="inputContent">
                                <ReactSVG src={envelopeIcon} className="is-pulled-left"/><input type="email" name="email" placeholder="Mail" className="is-pulled-right"/>
                            </div>
                            <div className="inputContent">
                                <ReactSVG src={phoneIcon} className="is-pulled-left"/><input type="text" name="telefono" placeholder="Teléfono de Contacto" className="is-pulled-right"/>
                            </div>
                            <div className="inputContent">
                                <ReactSVG src={textIcon} className="is-pulled-left"/><textarea type="text" name="mensajes" placeholder="Comentario" className="is-pulled-right"></textarea>
                            </div>
                            <input type="submit" name="enviar" value="Enviar" className="enviar is-pulled-right"/>
                            
                        </form>
                    </div>
                </div>
                <button class="modal-close is-large" aria-label="close" onClick={handleClose}></button>
            </div>
        </Base>
    )
}
export default Contacto;
