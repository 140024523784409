import React from "react";
import Base from "../layout/Base";
import Products from "../components/Products";
import Swal from "sweetalert2";

const Index = () => {
    let loc = window.location.href
    if(loc.search('success') > 0){
        Swal.fire({
            position: "top-center",
            icon: "success",
            title: "Gracias por contactarnos",
            showConfirmButton: false,
            timer: 2000,
        })
    }
    return (
        <Base>
              <div id='collection-component-1632323254850'></div>
              {/* <Products /> */}
        </Base>
    );
};

export default Index;
